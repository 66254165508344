import React from "react";

import { v4 as uuidv4 } from "uuid";


const storageKey = "surveySessionId";

export interface Session {
    readonly lastUsedAt: number;
    readonly id: string;
}

const newSession = () => {
    const session: Session = {
        id: uuidv4(),
        lastUsedAt: Date.now()
    };
    localStorage.setItem(storageKey, JSON.stringify(session));
    return session;
}

const updateSession = (session: Session): Session => {
    const updatedSession: Session = {
        id: session.id,
        lastUsedAt: Date.now()
    };
    localStorage.setItem(storageKey, JSON.stringify(updatedSession));
    return updatedSession;
}

export const loadOrCreateSession = (): Session => {
    const storedSession = localStorage.getItem(storageKey);
    if(storedSession === null) {
        return newSession();
    }

    // load session
    const session: Session = JSON.parse(storedSession);
    // one day in milliseconds
    const sessionValidMillis = 24 * 60 * 60 * 1000;
    const millisSinceLastUse = Date.now() - session.lastUsedAt;
    const isSessionValid = millisSinceLastUse < sessionValidMillis;
    if(!isSessionValid) {
        return newSession();
    }
    return updateSession(session);
}


const SessionContext: React.Context<Session> = React.createContext<Session>(loadOrCreateSession());

export default SessionContext;