import {Col, Layout, Popover, Row, Typography} from "antd";
import {Outlet} from "react-router-dom";
import SessionContext, {Session} from "../contexts/SessionContext";
import React from "react";
import {QuestionCircleFilled} from "@ant-design/icons";
const {
    Header,
    Content,
    Footer
} = Layout;

const BaseLayout = () => {
    const session: Session = React.useContext(SessionContext);

    return (
        <Layout>
            <Header style={{color: "#bbbbbb"}}>
                <Row>
                    <Col span={18} style={{fontSize: 16}}>Forschungskolloquium 24</Col>
                    <Col span={6} style={{fontSize: 12, textAlign: "right"}}><Popover content={session.id}><QuestionCircleFilled/></Popover></Col>
                </Row>
            </Header>
            <Content
                style={{
                    padding: "25px min(5vw, 50px)",
                    minHeight: "calc(100vh - 64px - 48px)",
                    width: "min(100vw, 1250px)",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}
            >
                <Outlet />
            </Content>
            <Footer/>
        </Layout>
    );
}

export default BaseLayout;