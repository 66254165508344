import React from "react";
import UserForm from "../components/UserForm";
import SessionContext from "../contexts/SessionContext";
import User from "../models/user";
import {Card} from "antd";
import SurveyProgressBar from "../components/SurveyProgressBar";
import UserService from "../services/userService";
import NotFoundError from "../exceptions/NotFoundError";
import {useNavigate} from "react-router-dom";

const UserInputView = () => {
    const {id} = React.useContext(SessionContext);
    const [user, setUser] = React.useState<User>({
        id: id,
        gender: "f",
        age: 0,
        bpmExperience: 0,
        bpmnExperience: 0,
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const navigate = useNavigate();

    const userService = new UserService();

    React.useEffect(() => {
        const loadUser = async () => {
            setIsLoading(true);
            let loadedUser: User;
            try {
                loadedUser = await userService.loadUser(id);
            } catch(e) {
                if(!(e instanceof NotFoundError)) {
                    throw e;
                }

                loadedUser = await userService.createUser(user);
            } finally {
                setIsLoading(false);
            }

            setUser(loadedUser);
        }

        loadUser();
    }, [id]);

    const submit = async (user: User) => {
        setIsLoading(true);
        try {
            await userService.createUser(user);
            navigate("/questions");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <SurveyProgressBar step={0} />
            <Card key={"user-details"} title={"User details"}>
                <UserForm
                    value={user}
                    onChange={setUser}
                    onSubmit={submit}
                    disabled={isLoading}
                />
            </Card>
        </>
    );
}

export default UserInputView;