import React from "react";
import {Steps} from "antd";

export interface Props {
    step: number;

    numSurveyItems?: number;
    curSurveyItem?: number;
}

const SurveyProgressBar = (props: Props) => {
    const getQuestionSubTitle = () => {
        if(props.step !== 1) return undefined;
        if(props.curSurveyItem === undefined) return undefined;
        if(props.numSurveyItems === undefined) return undefined;

        return `Question ${props.curSurveyItem + 1}/${props.numSurveyItems}`;
    }

    return (
        <Steps
            style={{marginBottom: 25}}
            items={[
                {title: "General Info"},
                {
                    title: "Questions",
                    subTitle: getQuestionSubTitle()
                },
                {title: "Complete"}
            ]}
            current={props.step}
            size={"small"}
            responsive={false}
        />
    );
}

export default SurveyProgressBar;