import React from "react";

import {Button, Card, Col, Divider, Empty, Flex, Input, notification, Row, Space, Spin, Tag} from "antd";
import {DoubleRightOutlined} from "@ant-design/icons";

import Question from "../models/question";

import AnswersService from "../services/answerService";
import QuestionService from "../services/questionService";

import SessionContext, {Session} from "../contexts/SessionContext";

import QuestionDisplay from "../components/QuestionDisplay";
import Rating from "../components/Rating";
import SurveyProgressBar from "../components/SurveyProgressBar";
import Answer from "../models/answer";


const QuestionsStepsView = () => {
    const session: Session = React.useContext(SessionContext);

    const [api, contextHolder] = notification.useNotification();

    const [questions, setQuestions] = React.useState<Question[]>([]);
    const [answers, setAnswers] = React.useState<Answer[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [currentValidity, setCurrentValidity] = React.useState<number>(1);
    const [currentInterpretability, setCurrentInterpretability] = React.useState<number>(1);
    const [currentSpeedup, setCurrentSpeedup] = React.useState<number>(1);
    const [currentComment, setCurrentComment] = React.useState("");

    React.useEffect(() => {
        const load = async () => {
            if(!session) return;
            if(!session.id) return;

            setIsLoading(true);
            try {
                const questionService = new QuestionService();
                const answerService = new AnswersService();
                const loadedQuestions = await questionService.list();
                const loadedAnswers = await answerService.find(session.id);
                setAnswers(loadedAnswers);

                const answeredQuestionIds = loadedAnswers.map(a => a.questionId);
                const unansweredQuestions = loadedQuestions.filter(q => answeredQuestionIds.indexOf(q.id) === -1);
                console.log(answeredQuestionIds)
                console.log(unansweredQuestions.map(q => answeredQuestionIds.indexOf(q.id)))
                setQuestions(unansweredQuestions);

                setCurrentIndex(0);
            } catch (e) {
                if(e instanceof Error) {
                    api.error({
                        message: e.name,
                        description: e.message
                    });
                }
            } finally {
                setIsLoading(false);
            }
        };

        load();
    }, [session.id]);

    const getFirstUnansweredQuestionIndex = () => {
        const answeredQuestionIds = answers.map(a => a.questionId);
        for (let i = 0; i < questions.length; ++i) {
            const question = questions[i];
            if(answeredQuestionIds.indexOf(question.id) === -1) {
                return i;
            }
        }
        return questions.length;
    }

    const sendAnswer = async () => {
        // TODO: show notification
        if(currentValidity === undefined) return;
        if(currentInterpretability === undefined) return;

        const answerService = new AnswersService();
        setIsSending(true);
        try {
            await answerService.create({
                questionId: questions[currentIndex].id,
                interpretability: currentInterpretability,
                speedup: currentSpeedup,
                validity: currentValidity,
                createdAt: new Date(Date.now()),
                createdBy: session.id,
                comment: currentComment
            });
        } catch (e) {
            if(e instanceof Error) {
                api.error({
                    message: e.name,
                    description: e.message
                });
            }
            else {
                console.log(e);
            }
        } finally {
            setIsSending(false);
        }
    }

    const nextQuestion = () => {
        setCurrentIndex(currentIndex + 1);
        setCurrentValidity(1);
        setCurrentInterpretability(1);
        setCurrentSpeedup(1);
        setCurrentComment("");
    }

    const renderInner = () => {
        if(isLoading) {
            return <Spin spinning={isLoading} />;
        }
        if(!questions) {
            return <Empty />;
        }
        if(currentIndex >= questions.length) {
            // done
            return <Empty description={"All questions answered."}/>;
        }
        return (
            <Space direction={"vertical"} style={{width: "100%"}}>
                <Card
                    title={<Flex align={"flex-end"} justify={"space-between"} wrap={"nowrap"}>
                        <span>Rating</span>
                        <Button
                            disabled={!currentInterpretability || !currentValidity}
                            type={"primary"}
                            loading={isSending}
                            onClick={async () => {
                                await sendAnswer();
                                nextQuestion();
                            }}
                        >
                            Save & Next <DoubleRightOutlined />
                        </Button>
                    </Flex>}
                    style={{width: "100%"}}
                >
                    <Flex align={"flex-end"} justify={"space-evenly"} wrap={"wrap"}>
                        <Rating
                            onChange={(v) => {
                                setCurrentValidity(v);
                            }}
                            value={currentValidity}
                            label={"Validity"}
                        />
                        <Rating
                            onChange={(v) => {
                                setCurrentInterpretability(v);
                            }}
                            value={currentInterpretability}
                            label={"Interpretability"}
                        />
                        <Rating
                            onChange={(v) => {
                                setCurrentSpeedup(v);
                            }}
                            value={currentSpeedup}
                            label={"Speedup"}
                        />
                    </Flex>
                </Card>

                <Row>
                    <Col xs={24}>
                        <QuestionDisplay question={questions[currentIndex]} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={24}>
                        <Card title={"Comments"}>
                            <Input.TextArea
                                rows={5}
                                value={currentComment}
                                onChange={e => setCurrentComment(e.target.value)}
                            />
                        </Card>
                    </Col>
                </Row>
            </Space>
        );
    }

    return (
        <>
            {contextHolder}
            <SurveyProgressBar
                step={1}
                curSurveyItem={currentIndex}
                numSurveyItems={questions ? questions.length : undefined}
            />
            {renderInner()}
        </>
    );

}

export default QuestionsStepsView;