import React from "react";
import {Button, Form, InputNumber, Select} from "antd";
import User from "../models/user";

interface Props {
    value: User;
    disabled?: boolean;
    onChange: (user: User) => void;
    onSubmit: (user: User) => void;
}

const UserForm = ({value, onChange, onSubmit, disabled}: Props) => {
    const [form] = Form.useForm<User>();
    const values = Form.useWatch([], form);

    const [canSubmit, setCanSubmit] = React.useState(false);

    React.useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => {
                console.log("valid")
                setCanSubmit(true)
            })
            .catch(() => setCanSubmit(false));
    }, [values, form]);

    return (
        <Form
            form={form}
            labelCol={{xs: {span: 24}, sm: {span: 10}}}
            wrapperCol={{xs: {span: 24}, sm: {span: 14}}}
            labelWrap={true}
            labelAlign={"right"}
            colon={false}
        >
            <Form.Item label={"Gender"}>
                <Select
                    value={value.gender}
                    options={[
                        {value: "m", label: "male"},
                        {value: "f", label: "female"},
                        {value: "d", label: "other"},
                    ]}
                    onChange={(v) => {
                        onChange({
                            ...value,
                            gender: v
                        })
                    }}
                />
            </Form.Item>

            <Form.Item label={"Age"}>
                <InputNumber
                    style={{width: "100%"}}
                    value={value.age}
                    onChange={(v) => {
                        if(v == null) return;
                        onChange({
                            ...value,
                            age: v
                        })
                    }}
                    addonAfter={"Years"}
                />
            </Form.Item>

            <Form.Item
                label={"Experience in BPM"}
                rules={[
                    {required: true, min: 0, max: 100}
                ]}
            >
                <InputNumber
                    style={{width: "100%"}}
                    value={value.bpmExperience}
                    onChange={(v) => {
                        if(v == null) return;
                        onChange({
                            ...value,
                            bpmExperience: v
                        })
                    }}
                    addonAfter={"Years"}
                />
            </Form.Item>

            <Form.Item label={"Experience with BPMN standard"}>
                <InputNumber
                    style={{width: "100%"}}
                    value={value.bpmnExperience}
                    onChange={(v) => {
                        if(v == null) return;
                        onChange({
                            ...value,
                            bpmnExperience: v
                        })
                    }}
                    addonAfter={"Years"}
                />
            </Form.Item>

            <Form.Item wrapperCol={{xs: {span: 24}, sm: {offset: 10, span: 14}}}>
                <Button
                    type={"primary"}
                    disabled={!canSubmit || disabled}
                    onClick={() => onSubmit(value)}
                >
                    Next
                </Button>
            </Form.Item>
        </Form>
    );
}

export default UserForm;