import React from "react";

import {Card, Col, Image, Row} from "antd";

import Question from "../models/question";

interface Props {
    question: Question;
}

const QuestionDisplay = (props: Props) => {
    return (
        <Row gutter={10} justify={"center"} align={"stretch"}>
            <Col xs={24} sm={12} style={{height: "100%"}}>
                <Card title={"Reference"} style={{textAlign: "center", height: "100%"}}>
                    <Image src={props.question.referenceImagePath} />
                </Card>
            </Col>
            <Col xs={24} sm={12} style={{height: "100%"}}>
                <Card title={"Suggestion"} style={{textAlign: "center", height: "100%"}}>
                    <Image src={props.question.imagePath} />
                </Card>
            </Col>
        </Row>
    );
}

export default QuestionDisplay;