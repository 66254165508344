import {Button, Image, InputNumber, notification, Table} from "antd";
import React from "react";
import Question from "../models/question";
import QuestionService from "../services/questionService";
import {ColumnsType} from "antd/es/table";
import {Link} from "react-router-dom";
import {SaveOutlined} from "@ant-design/icons";

const QuestionsListView = () => {
    const [api, contextHolder] = notification.useNotification();

    const [isLoading, setIsLoading] = React.useState(false);
    const [questions, setQuestions] = React.useState<Question[]>([]);
    const [questionOrder, setQuestionOrder] = React.useState<{[questionId: number]: number}>({});

    React.useEffect(()=> {
        const load = async ()=> {
            setIsLoading(true);

            try {
                const questionService = new QuestionService();
                const loadedQuestions = await questionService.list();
                setQuestions(loadedQuestions);
                setQuestionOrder(loadedQuestions.reduce<{[questionId: number]: number}>((total, question) => {
                    total[question.id] = question.priority;
                    return total;
                }, {}))
            } catch (e) {
                if(e instanceof Error) {
                    api.error({
                        message: e.name,
                        description: e.message
                    });
                }
            } finally {
                setIsLoading(false);
            }
        };

        load();
    }, []);

    const columns: ColumnsType = [
        {
            title: "Id",
            key: "questionId",
            dataIndex: "id",
            render: (_, record) => {
                return <Link to={`/questions/${record.id}`}>{record.id}</Link>;
            }
        },
        {
            title: "Image",
            key: "questionImage",
            render: (_, record: Question) => {
                return <Image src={record.imagePath} height={25}/>;
            }
        },
        {
            title: "Order",
            key: "order",
            render: (_, record: Question) => {
                return <InputNumber
                    value={questionOrder[record.id]}
                    onChange={(v) => {
                        if(v == null) return;
                        questionOrder[record.id] = v;
                        setQuestionOrder({...questionOrder});
                    }}
                />;
            }
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record: Question) =>
                <Button
                    icon={<SaveOutlined />}
                    onClick={async () => {
                        const questionService = new QuestionService();
                        record.priority = questionOrder[record.id];
                        await questionService.update(record);
                        api.success({
                            message: "Updated successfully"
                        })
                    }}
                />
        }
    ];

    return (
        <>
            {contextHolder}
            <Table
                key={"table"}
                columns={columns}
                dataSource={questions}
                loading={isLoading}
            />
        </>
    );
}

export default QuestionsListView;