import Question, {NonPersistedQuestion} from "../models/question";

export default class QuestionService {
    private readonly endpoint: string;

    constructor() {
        this.endpoint = `http://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/questions`;
    }

    public load = async (id: number): Promise<Question> => {
        const response = await fetch(`${this.endpoint}/${id}`, {
            method: "GET",
        });
        return await response.json();
    }

    public update = async (question: Question): Promise<Question> => {
        const response = await fetch(`${this.endpoint}/${question.id}`,{
            method: "POST",
            body: JSON.stringify(question),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await response.json();
    }

    public list = async (): Promise<Question[]> => {
        const response = await fetch(this.endpoint, {
            method: "GET",
        });
        return await response.json();
    }

    public create = async (question: NonPersistedQuestion, questionImage: File, referenceImage: File): Promise<Question> => {
        const formData = new FormData();
        const questionBlob = new Blob([JSON.stringify(question)], {
            type: "application/json"
        });
        formData.append("questionData", questionBlob);
        formData.append("questionImage", questionImage);
        formData.append("referenceImage", referenceImage);

        const response = await fetch(this.endpoint, {
            method: "POST",
            body: formData
        });

        return await response.json();
    }
}