import React from "react";

import {Button, notification, Popconfirm, Table} from "antd";
import AnswersService from "../services/answerService";
import Answer from "../models/answer";
import {DeleteFilled} from "@ant-design/icons";

const Admin = () => {
    const [api, contextHolder] = notification.useNotification();

    const [answers, setAnswers] = React.useState<Answer[]>([]);
    const [isLoadingAnswers, setIsLoadingAnswers] = React.useState(false);

    const answerService = new AnswersService();

    const load = async () => {
        setIsLoadingAnswers(true);
        try {
            setAnswers(await answerService.list());
        } finally {
            setIsLoadingAnswers(false);
        }
    }

    React.useEffect(() => {
        load();
    }, []);

    return (
        <>
            {contextHolder}
            <Popconfirm
                title={"Really delete ALL answers?"}
                onConfirm={async () => {
                    const answersService = new AnswersService();
                    await answersService.deleteAll();
                    api.success({
                        message: "Success",
                        description: "Deleted all answers."
                    });
                    await load();
                }}
            >
                <Button>Delete all answers</Button>
            </Popconfirm>

            <Table
                loading={isLoadingAnswers}
                columns={[
                    {dataIndex: "interpretability", title: "Interpretability"},
                    {dataIndex: "validity", title: "Validity"},
                    {dataIndex: "speedup", title: "Speed-up"},
                    {dataIndex: "createdBy", title: "Created by"},
                    {
                        dataIndex: "createdAt", title: "Created at", render: (_, record: Answer) => {
                            return record.createdAt.toUTCString();
                        }
                    },
                    {
                        render: (_, record: Answer) =>
                            <Popconfirm title={"Really delete answer?"} onConfirm={() => answerService.deleteOne(record.id)}>
                                <Button icon={<DeleteFilled />} />
                            </Popconfirm>
                    }
                ]}
                dataSource={answers}
            />
        </>
    );
}

export default Admin;