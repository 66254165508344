import QuestionDisplay from "../components/QuestionDisplay";
import React from "react";
import {Empty, notification, Spin} from "antd";
import Question from "../models/question";
import QuestionService from "../services/questionService";
import {useParams} from "react-router-dom";
import Rating from "../components/Rating";

const QuestionView = () => {
    const [api, contextHolder] = notification.useNotification();

    const [question, setQuestion] = React.useState<Question>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [value, setValue] = React.useState(50);

    const { questionId } = useParams();

    React.useEffect(() => {
        if(!questionId) return;

        const load = async () => {
            setIsLoading(true);
            try {
                const questionService = new QuestionService();
                const loadedQuestion = await questionService.load(parseInt(questionId));
                setQuestion(loadedQuestion);
            } catch (e) {
                if(e instanceof Error) {
                    api.error({
                        message: e.name,
                        description: e.message
                    });
                }
            } finally {
                setIsLoading(false);
            }
        };

        load();
    }, [questionId]);

    const renderInner = () => {
        if(isLoading) {
            return <Spin spinning={isLoading} />;
        }
        if(!question) {
            return <Empty />;
        }
        return (
            <>
                <div><QuestionDisplay question={question} /></div>
                <div><Rating onChange={setValue} value={value} /></div>
            </>
        );
    }

    return (
        <>
            {contextHolder}
            {renderInner()}
        </>
    );

}

export default QuestionView;