import React from "react";
import {Button, Form, Input, InputNumber, notification, Upload, UploadFile} from "antd";
import {NonPersistedQuestion} from "../models/question";
import QuestionService from "../services/questionService";
import {InboxOutlined} from "@ant-design/icons";

const QuestionCreateView = () => {
    const [question, setQuestion] = React.useState<Partial<NonPersistedQuestion>>({});
    const [questionImage, setQuestionImage] = React.useState<File | undefined>();
    const [questionImageUploadFiles, setQuestionImageUploadFiles] = React.useState<UploadFile[]>([]);
    const [questionReferenceImage, setQuestionReferenceImage] = React.useState<File | undefined>();
    const [questionReferenceImageUploadFiles, setQuestionReferenceImageUploadFiles] = React.useState<UploadFile[]>([]);

    const [api, contextHolder] = notification.useNotification();

    const validateField = (question: Partial<NonPersistedQuestion>, field: keyof NonPersistedQuestion) => {
        if(!Object.hasOwn(question, field) || question[field] === undefined) {
            api.error({
                message: "Incomplete",
                description: `Missing value for field "${field}"!`
            });
            return false;
        }
        return true;
    }

    const validateQuestion = (): NonPersistedQuestion | undefined => {
        if(!validateField(question, "text")) return;
        if(!validateField(question, "documentId")) return;
        if(!validateField(question, "precision")) return;
        if(!validateField(question, "recall")) return;
        if(!validateField(question, "priority")) return;

        return question as NonPersistedQuestion;
    }

    const submit = async () => {
        if (questionImage === undefined) {
            api.error({
                message: "Incomplete",
                description: "Missing question image!"
            });
            return;
        }
        if (questionReferenceImage === undefined) {
            api.error({
                message: "Incomplete",
                description: "Missing reference image!"
            });
            return;
        }

        const validatedQuestion = validateQuestion();
        if(!validatedQuestion) return;

        const questionService = new QuestionService();
        try {
            await questionService.create({
                text: validatedQuestion.text,
                documentId: validatedQuestion.documentId,
                precision: validatedQuestion.precision,
                recall: validatedQuestion.recall,
                displayed: true,
                priority: validatedQuestion.priority
            }, questionImage, questionReferenceImage);
            setQuestion({
                documentId: question.documentId,
                text: question.text
            });
            setQuestionImage(undefined);
            setQuestionImageUploadFiles([]);
            api.success({
                message: "Success",
                description: "Question created successfully."
            })
        } catch (e) {
            console.log(e);
            if(e instanceof Error) {
                api.error({
                    message: e.name,
                    description: e.message
                });
            }
        }
    }

    const renderForm = () => {
        return (
            <Form>
                <Form.Item
                    label={"Document ID"}
                >
                    <Input
                        value={question.documentId}
                        onChange={(v) => setQuestion({...question, documentId: v.target.value})}
                    />
                </Form.Item>
                <Form.Item
                    label={"Priority"}
                >
                    <InputNumber
                        value={question.priority}
                        onChange={v => setQuestion({...question, priority: v !== null ? v : undefined})}
                    />
                </Form.Item>
                <Form.Item
                    name={"text"}
                    label={"Text"}
                >
                    <Input.TextArea
                        value={question.text}
                        onChange={(e) => {
                            setQuestion({...question, text: e.target.value});
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"precision"}
                    label={"Precision"}
                >
                    <InputNumber
                        min={0}
                        max={100}
                        value={question.precision}
                        onChange={(v) => {
                            setQuestion({
                                ...question,
                                precision: v !== null ? v : undefined
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"recall"}
                    label={"Recall"}
                >
                    <InputNumber
                        min={0}
                        max={100}
                        value={question.recall}
                        onChange={(v) => {
                            setQuestion({
                                ...question,
                                recall: v !== null ? v : undefined
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item label={"Question Image"}>
                    <Upload.Dragger
                        multiple={false}
                        onDrop={(e) => setQuestionImage(e.dataTransfer.files[0])}
                        beforeUpload={() => false}
                        onChange={e => setQuestionImageUploadFiles(e.fileList.slice(-1))}
                        fileList={questionImageUploadFiles}
                    >
                        <InboxOutlined />
                        <div>Upload File</div>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item label={"Reference Image"}>
                    <Upload.Dragger
                        multiple={false}
                        onDrop={(e) => setQuestionReferenceImage(e.dataTransfer.files[0])}
                        beforeUpload={() => false}
                        onChange={e => setQuestionReferenceImageUploadFiles(e.fileList.slice(-1))}
                        fileList={questionReferenceImageUploadFiles}
                    >
                        <InboxOutlined />
                        <div>Upload File</div>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item>
                    <Button type={"primary"} onClick={submit}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    return (
        <>
            {contextHolder}
            {renderForm()}
        </>
    );
}

export default QuestionCreateView;