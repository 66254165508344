import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {DoubleRightOutlined} from "@ant-design/icons";

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <>
            <h1>Welcome</h1>
            <p>Thank you for taking the time to participate in this survey.</p>
            <Button onClick={() => navigate("/user")} icon={<DoubleRightOutlined />} type={"primary"}>Start</Button>
        </>
    );
}

export default HomePage;