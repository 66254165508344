import React from "react";
import {Col, Row, SliderSingleProps, Rate, Tag} from "antd";

export interface Props {
    value: number;
    onChange: (value: number) => void;
    label?: string;
}

const Rating = (props: Props) => {
    const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value}%`;

    const onChange = (value: number | null) => {
        if(!value) return;
        props.onChange(value);
    }

    const renderLabel = () => {
        if(!props.label) {
            return `${props.value}`;
        }

        return `${props.label}: ${props.value}`;
    }

    return (
        <div>
            <Tag>
                {renderLabel()}
            </Tag>
            <Rate value={props.value} onChange={onChange} />
        </div>
        // <Row style={{paddingBottom: 25}} align={"middle"}>
        //     {/*<Col xs={14} sm={18} md={20} lg={22}>
        //         <Slider
        //             tooltip={{formatter}} value={props.value}
        //             onChange={onChange}
        //             min={0}
        //             max={100}
        //         />
        //     </Col>
        //     <Col xs={10} sm={6} md={4} lg={2} style={{textAlign: "right"}}>
        //         <Tag>{renderLabel()}</Tag>
        //     </Col>*/}
        //     <Col xs={12} style={{textAlign: "right"}}>
        //
        //     </Col>
        //     <Col xs={12}>
        //     </Col>
        // </Row>
    );
}

export default Rating;