export const linearRegression = (xs: number[], ys: number[]): [number, number] => {
    if(xs.length !== ys.length) {
        throw new Error("Need the same number of x and y values.");
    }

    if(xs.length === 0) {
        throw new Error("Need at least one point for linear regression.");
    }

    const xSum = xs.reduce((total, x) => total + x, 0);
    const ySum = ys.reduce((total, y) => total + y, 0);
    const xSquaredSum = xs.reduce((total, x) => total + x * x, 0);
    const xySum = xs.reduce((total, x, i) => total + x * ys[i], 0);
    const count = xs.length;

    // calc coefficients for y = m*x + b
    const m = (count * xySum - xSum * ySum) / (count * xSquaredSum - xSum * xSum);
    const b = (ySum / count) - (m * xSum) / count;

    return [m, b];
}

export const fScore = (precision: number, recall: number, beta: number = 1.0) => {
    const betaSqr = beta * beta;
    return (1 + betaSqr) * (precision * recall) / (betaSqr * precision + recall);
}

export const pearsonR = (xs: number[], ys: number[]): number => {
    if (xs.length !== ys.length) throw new Error("Length of x and y arrays need to be the same.");
    if (xs.length === 0) throw new Error("Need at least one value for x and y.");

    const xMean = xs.reduce((sum, x) => sum + x, 0) / xs.length;
    const yMean = ys.reduce((sum, x) => sum + x, 0) / ys.length;

    const deviationProduct = xs.map((_, i) => (xs[i] - xMean) * (ys[i] - yMean)).reduce((sum, x) => sum + x, 0);

    const xSqrDeviation = xs.map(x => Math.pow(x - xMean, 2)).reduce((sum, x) => sum + x, 0);
    const ySqrDeviation = ys.map(x => Math.pow(x - xMean, 2)).reduce((sum, x) => sum + x, 0);

    if(xSqrDeviation === 0) throw new Error("Invalid data for x array, no deviation.")
    if(ySqrDeviation === 0) throw new Error("Invalid data for y array, no deviation.")

    return deviationProduct / (Math.sqrt(xSqrDeviation) * Math.sqrt(ySqrDeviation));
}