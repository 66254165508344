import Answer, {NonPersistedAnswer} from "../models/answer";


type Payload = Omit<NonPersistedAnswer, "createdAt"> & {createdAt: number};

export default class AnswersService {
    private readonly endpoint: string;

    constructor() {
        this.endpoint = `http://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/answers`;
    }

    public create = async (answer: NonPersistedAnswer): Promise<Answer> => {
        const payload: Payload = {
            questionId: answer.questionId,
            interpretability: answer.interpretability,
            speedup: answer.speedup,
            validity: answer.validity,
            comment: answer.comment,
            createdAt: answer.createdAt.getTime(),
            createdBy: answer.createdBy
        };
        const response = await fetch(this.endpoint, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return this.loadAnswer(await response.json());
    }

    private loadAnswer = (answer: any): Answer => {
        return {
            ...answer,
            createdAt: new Date(answer.createdAt * 1000)
        }
    }

    public list = async (): Promise<Answer[]> => {
        const response = await fetch(this.endpoint, {
            method: "GET"
        });
        return (await response.json()).map(this.loadAnswer);
    }

    public find = async (userId: string): Promise<Answer[]> => {
        console.log("finding for ", userId)
        const response = await fetch(this.endpoint + "?" + new URLSearchParams({
            "userId": userId
        }), {
            method: "GET",
        });

        const answers = (await response.json()).map(this.loadAnswer);
        console.log(`Found ${answers.length} answers`)
        return answers;
    }

    public deleteAll = async (): Promise<boolean> => {
        const response = await fetch(this.endpoint, {
            method: "DELETE"
        });
        return (await response.json()).success;
    }

    public deleteOne = async (answerId: number): Promise<boolean> => {
        const response = await fetch(`${this.endpoint}/${answerId}`, {
            method: "DELETE"
        });
        return (await response.json()).success;
    }
}