import React from "react";

import Chart from "react-apexcharts";

import Answer from "../models/answer";
import AnswersService from "../services/answerService";
import Question from "../models/question";
import QuestionService from "../services/questionService";
import {ApexOptions} from "apexcharts";
import {Button, Empty, Form, Image, Select, Slider} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import {fScore, linearRegression, pearsonR} from "../services/fittingService";

const ResultsView = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [answers, setAnswers] = React.useState<Answer[]>([]);
    const [questions, setQuestions] = React.useState<Question[]>([]);

    const [metric, setMetric] = React.useState<"interpretability" | "validity" | "speedup">("validity");

    const [offset, setOffset] = React.useState(0);
    const [beta, setBeta] = React.useState(1);

    const [jitter, setJitter] = React.useState(0.0);

    const data = React.useMemo(() => {
        const questionsById = questions.reduce<{[id: string]: Question}>((res, q) => {
            res[q.id.toFixed(0)] = q;
            return res;
        }, {});
        return answers.map(a => {
            const question = questionsById[a.questionId];
            const f1 = fScore(question.precision, question.recall, beta);
            const rawRating = a[metric];
            const rating = offset + (100.0 - offset) * rawRating / 5.0;

            return {
                "x": f1 + Math.random() * jitter,
                "y": rating + Math.random() * jitter,
                "image": question.imagePath
            };
        });
    }, [answers, metric, offset, questions, beta, jitter]);


    const widthRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        load();
    }, []);

    const load = async () => {
        console.log("Loading answers...")
        const start = performance.now();
        setIsLoading(true);
        try {
            const answerService = new AnswersService();
            const loadedAnswers = await answerService.list();

            const questionService = new QuestionService();
            const loadedQuestions = await questionService.list();

            setQuestions(loadedQuestions);
            setAnswers(loadedAnswers);
        } finally {
            setIsLoading(false);
            console.log(`Loading ${answers.length} answers took ${performance.now() - start} ms.`)
        }
    };

    const pearsonRFromAnswers = (): number => {
        return pearsonR(
            data.map(e => e.x),
            data.map(e => e.y)
        );
    }

    const seriesFromAnswers = (): ApexOptions["series"] => {
        console.log(`Building series for ${answers.length} answers...`);
        const start = performance.now();

        const [m, b] = linearRegression(
            data.map(e => e.x),
            data.map(e => e.y),
        );

        console.log(`Done after ${performance.now() - start} ms.`)

        return [{
            type: "scatter",
            name: "data",
            data: data
        }, {
            type: "line",
            name: "identity",
            data: [{"x": -10, "y": -10}, {"x": 110, "y": 110}]
        }, {
            type: "line",
            name: "fitted",
            data: [{"x": -10, "y": -10 * m + b}, {"x": 110, "y": 110 * m + b}]
        }];
    }

    const options: ApexOptions = {
        chart: {
            type: "line",
            height: 350,
            width: "100vw",
            zoom: {
                enabled: true,
                type: "xy"
            }
        },
        xaxis: {
            type: "numeric",
            tickAmount: 10,
            labels: {
                formatter: (v: any) => `${v}%`
            },
            min: 0,
            max: 100
        },
        yaxis: {
            tickAmount: 10,
            labels: {
                formatter: (v: any) => `${v}%`
            },
            min: 0,
            max: 100
        },
        markers: {
            size: [6, 0]
        },
        tooltip: {
            custom: ((p) => {
                const imagePath = data[p.dataPointIndex].image;
                return `<img style="width: 300px" src="${imagePath}" />`;
            }),
            intersect: true,
            shared: false
        },

    };

    if(questions.length === 0 || answers.length === 0) {
        return <Empty/>;
    }

    return (
        <div style={{width: "min(100%, 600px)", marginLeft: "auto", marginRight: "auto"}} ref={widthRef}>
            <div style={{textAlign: "right"}}>
                <Select onChange={setMetric} style={{width: "150px"}} defaultValue={metric} value={metric}>
                    <Select.Option value={"validity"}>Validity</Select.Option>
                    <Select.Option value={"interpretability"}>Interpretability</Select.Option>
                    <Select.Option value={"speedup"}>Speed Up</Select.Option>
                </Select>
                <Button
                    icon={<ReloadOutlined/>}
                    type={"primary"}
                    onClick={load}
                    loading={isLoading}
                />
            </div>
            <div>Pearson r: {pearsonRFromAnswers().toFixed(2)}</div>
            <Chart
                options={options}
                series={seriesFromAnswers()}
                width={widthRef.current?.offsetWidth}
                type={"line"}
            />
            <Form>
                <Form.Item
                    label={"Offset"}
                >
                    <Slider
                        min={0}
                        max={100}
                        step={5}
                        onChange={setOffset}
                    />
                </Form.Item>

                <Form.Item
                    label={"Beta"}
                >
                    <Slider
                        min={-20}
                        max={20}
                        step={.5}
                        value={Math.log2(beta)}
                        onChange={(v) => {
                            setBeta(Math.pow(2, v));
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label={"Jitter"}
                >
                    <Slider
                        min={0.0}
                        max={20.0}
                        step={1}
                        value={jitter}
                        onChange={setJitter}
                    />
                </Form.Item>
            </Form>
        </div>
    );
}

export default ResultsView;