import React from 'react';
import './App.css';

import {BrowserRouter, Route, Routes} from "react-router-dom";

import QuestionsListView from "./views/QuestionsListView";
import QuestionView from "./views/QuestionView";
import QuestionsStepsView from "./views/QuestionsStepsView";
import BaseLayout from "./layouts/BaseLayout";
import SessionContext, {loadOrCreateSession} from "./contexts/SessionContext";
import ResultsView from "./views/ResultsView";
import UserInputView from "./views/UserInputView";
import QuestionCreateView from "./views/QuestionCreateView";
import HomePage from "./views/HomePage";
import Admin from "./views/Admin";


function App() {
  return (
      <SessionContext.Provider value={loadOrCreateSession()}>
          <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<BaseLayout/>}>
                    <Route path={""} element={<HomePage/>} />
                    <Route path={"admin"} element={<Admin />} />
                    <Route path={"user"} element={<UserInputView/>} />
                    <Route path={"questions/all"} element={<QuestionsListView />} />
                    <Route path={"questions/new"} element={<QuestionCreateView />} />
                    <Route path={"questions/:questionId"} element={<QuestionView />} />
                    <Route path={"questions"} element={<QuestionsStepsView />} />
                    <Route path={"results"} element={<ResultsView/>} />
                </Route>
            </Routes>
          </BrowserRouter>
      </SessionContext.Provider>
  );
}

export default App;
