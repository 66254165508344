import User from "../models/user";
import NotFoundError from "../exceptions/NotFoundError";


export default class UserService {
    private readonly endpoint: string;

    constructor() {
        this.endpoint = `http://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/users`;
    }

    public createUser = async (user: User): Promise<User> => {
        const response = await fetch(this.endpoint, {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    public loadUser = async (userId: string): Promise<User> => {
        const response = await fetch(`${this.endpoint}/${userId}`);
        console.log(response.status)
        if(response.status === 404) {
            return Promise.reject(new NotFoundError("User not found."));
        }
        return await response.json();
    }
}

